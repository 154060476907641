import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout/Layout';
import SEO from 'components/seo';
import Blog from 'components/Blog/Blog';

const BlogPage = ({data}) => (
	<Layout>
		<SEO title="Blog" />
		<Blog data={data}/>
	</Layout>
)

export default BlogPage

export const query = graphql`
  {
		allContentfulBlogPost(
			sort: { fields: [date], order: DESC } 
		) {
				nodes {
					title
					date
					slug
					subtitle
					image {
						fluid(maxWidth: 537) {
							...GatsbyContentfulFluid_tracedSVG
						}
					}
					author {
						name
						position
					}
				}
			}
	}
`
